import * as React from 'react'
import ButtonPrimary from '@/components/ButtonPrimary'

const NotFoundPage: React.FunctionComponent = () => {
    return (
        <>
            <div className="flex h-screen w-screen items-center justify-center bg-aqua">
                <div className="rounded-md bg-white px-40 py-20 shadow-xl">
                    <div className="flex flex-col items-center">
                        <h1 className="text-black-600 text-9xl font-bold">404</h1>

                        <h6 className="text-gray-800 mb-2 text-center text-2xl font-bold md:text-3xl">
                            <span className="text-red-500">Oops!</span> Page not found
                        </h6>

                        <p className="mb-8 text-center md:text-lg">
                            The page you&apos;re looking for doesn&apos;t exist.
                        </p>

                        <ButtonPrimary
                            data={{
                                __typename: 'modules_generalButton_BlockType',
                                typeHandle: 'generalButton',
                                buttonUrl: '/',
                                buttonTitle: 'Return home',
                                linkOrSection: null,
                                block: null,
                            }}
                            theme="white"
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default NotFoundPage
